// @flow

import { ACTIONS } from './actions';

import type { Plant } from 'types';

export type PlantState = {
  isFetching: boolean,
  hasError: boolean,
  error: {
    message: string,
    status: number
  },
  plants: Plant[]
};

export type PlantAction = {
  type: string | null,
  error: {
    message: string,
    status: number
  },
  plants: Plant[]
};

const defaultState = {
  isFetching: false,
  hasError: false,
  error: {},
  plants: [],
  enabled: true,
  showModal: false
};

// plant reducer
const plant = (state: PlantState = defaultState, action: PlantAction) => {
  switch (action.type) {
    case ACTIONS.PLANT.REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case ACTIONS.PLANT.ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.error
      };

    case ACTIONS.PLANT.RECEIVE:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        plants: action.plants
      };

    case ACTIONS.PLANT.DISABLE:
      return {
        ...state,
        enabled: false
      };

    case ACTIONS.PLANT.ENABLE:
      return {
        ...state,
        enabled: true
      };

    case ACTIONS.PLANT.MODAL.SHOW:
      return {
        ...state,
        showModal: true
      };

    case ACTIONS.PLANT.MODAL.HIDE:
      return {
        ...state,
        showModal: false
      };

    default:
      return state;
  }
};

export default plant;
