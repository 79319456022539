//@flow

import * as React from 'react';

import './LiteratureType.scss';

import type { Element } from 'react';

type Props = {
  activeLitType: boolean,
  disabled: boolean,
  icon: Element<any>,
  id: string,
  title: string,
  selectorOnClick: (string) => void
};

/**
 * Singular literature asset, includes icon and title.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const LiteratureType = (props: Props) => {
  const { activeLitType, disabled, icon, id, title, selectorOnClick } = props;

  const handleOnClick = () => {
    if (!selectorOnClick) return;
    selectorOnClick(id);
  };

  return (
    <div
      className={`img-title ${disabled ? 'is-disabled' : ''} ${
        activeLitType ? 'is-active' : ''
      }`}
      onClick={handleOnClick}
    >
      {icon}
      <h2>{title}</h2>
    </div>
  );
};

export default LiteratureType;
