// @flow

import { ACTIONS } from './actions';

export type CurrentState = {
  [key: string]: any
};

export type CurrentAction = {
  type: string | null,
  key: string,
  value: any
};

const defaultState = {};

// butt reducer
const current = (state: CurrentState = defaultState, action: CurrentAction) => {
  switch (action.type) {
    case ACTIONS.CURRENT.SET:
      return {
        ...state,
        [action.key]: action.value
      };

    default:
      return state;
  }
};

export default current;
