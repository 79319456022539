// @flow

import { ACTIONS } from './actions';

import type { Butt } from 'types';

export type ButtState = {
  active: boolean,
  activeButt: string,
  butts: Butt[],
  enabled: boolean,
  hoverButt: string
};

export type ButtAction = {
  type: string | null,
  butt: Butt,
  id: string,
  title: string
};

const defaultState = {
  active: false,
  activeButt: '',
  butts: [],
  enabled: true,
  hoverButt: ''
};

// butt reducer
const butt = (state: ButtState = defaultState, action: ButtAction) => {
  switch (action.type) {
    case ACTIONS.BUTTBAR.ACTIVATE:
      return {
        ...state,
        active: true
      };

    case ACTIONS.BUTTBAR.DEACTIVATE:
      return {
        ...state,
        active: false
      };

    case ACTIONS.BUTTBAR.ENABLE:
      return {
        ...state,
        enabled: true
      };

    case ACTIONS.BUTTBAR.DISABLE:
      return {
        ...state,
        enabled: false
      };

    case ACTIONS.BUTT.RECEIVE:
      return {
        ...state,
        butts: [...state.butts, action.butt]
      };

    case ACTIONS.BUTT.ACTIVATE:
      return {
        ...state,
        activeButt: action.id
      };

    case ACTIONS.BUTT.DEACTIVATE:
      return {
        ...state,
        activeButt: ''
      };

    case ACTIONS.BUTT.HOVER:
      return {
        ...state,
        hoverButt: action.id
      };

    case ACTIONS.BUTT.DEHOVER:
      return {
        ...state,
        hoverButt: ''
      };

    case ACTIONS.BUTT.CLEAR:
      return {
        ...state,
        butts: []
      };

    default:
      return state;
  }
};

export default butt;
