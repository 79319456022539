// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const HamburgerIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 22"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Menu Icon</title>

      <g>
        <rect width="32" height="2" />
      </g>
      <g>
        <rect y="10" width="32" height="2" />
      </g>
      <g>
        <rect y="20" width="32" height="2" />
      </g>
    </svg>
  );
};

export default HamburgerIcon;
