// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const ArrowLeftIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 19.1"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Left Arrow Icon</title>

      <polygon points="32,8.5 3.8,8.5 11,1.4 9.5,0 0,9.5 9.5,19.1 11,17.7 3.8,10.5 32,10.5 " />
    </svg>
  );
};

export default ArrowLeftIcon;
