// @flow

import React from 'react';

import './FilteredList.scss';
import FilterSelector from '../FilterSelector/FilterSelector';
import ProductCardList from 'components/ProductCardList/ProductCardList';

import type { Filter } from 'features/FilteredList/types';

type Props = {
  activeFilter: string,
  filters: Filter[],
  onFilterClick: (string) => void,
  onSelectorClick: () => void,
  products: string[],
  selectorOpen: boolean,
  showPrimary: boolean
};

/**
 * The core FilteredList presentational component.
 * - Accepts a string ID for the currently active filter
 * - An array of product ids to pass on to the Card list
 * - an array of Filter objects defining the current set of filters
 * - a function to execute when user click's on a filter
 * - a boolean to determine if we should output the "primary" notation
 *   on a card or not
 *
 * @author Todd Miller <github.com/Toddses>
 */
const FilteredList = (props: Props) => {
  const {
    activeFilter,
    filters,
    onFilterClick,
    onSelectorClick,
    products,
    selectorOpen,
    showPrimary
  } = props;

  return (
    <div id="filtered-list-container">
      <div className="selector-wrapper">
        <FilterSelector
          activeFilter={activeFilter}
          filters={filters}
          onFilterClick={onFilterClick}
          onSelectorClick={onSelectorClick}
          selectorOpen={selectorOpen}
        />
      </div>

      <div className="list-wrapper">
        <ProductCardList products={products} showPrimary={showPrimary} />
      </div>
    </div>
  );
};

export default FilteredList;
