// @flow

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterHistory, withRouter } from 'react-router-dom';

import BackIcon from 'icons/ArrowLeftIcon';
import LibraryView from 'components/LibraryView/LibraryView';
import SideBar from 'features/SideBar';

import { getAssets } from 'store/asset/selectors';
import { setCurrent } from 'store/current/actions';
import { fetchLibrary } from 'store/library/actions';
import {
  getIsFetching as getIsLibraryFetching,
  getLibrary
} from 'store/library/selectors';
import { fetchPlants } from 'store/plant/actions';
import { getIsFetching as getIsPlantsFetching } from 'store/plant/selectors';
import {
  createWidget,
  enableWidgets,
  removeWidgets,
  setTheme
} from 'store/sidebar/actions';

type Props = {
  history: RouterHistory
};

/**
 * Manages data and functionality for the LibraryView
 * @author Todd Miller <github.com/Toddses>
 */
const LibraryContainer = (props: Props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const isLibraryFetching = useSelector(getIsLibraryFetching);
  const isPlantsFetching = useSelector(getIsPlantsFetching);
  const library = useSelector(getLibrary);
  const assets = useSelector(getAssets(library));

  /**
   * FETCH
   */
  useEffect(() => {
    dispatch(fetchPlants());
    dispatch(fetchLibrary());
  }, [dispatch]);

  /**
   * LOADING
   */
  useEffect(() => {
    if (isLibraryFetching || isPlantsFetching)
      dispatch(setCurrent('LoadingActive', true));
    else dispatch(setCurrent('LoadingActive', false));

    return () => {
      dispatch(setCurrent('LoadingActive', true));
    };
  }, [dispatch, isLibraryFetching, isPlantsFetching]);

  /**
   * WIDGETS
   */
  useEffect(() => {
    // widget icons
    const backIcon = <BackIcon iconTitle="unit-back-icon" />;

    // widget handlers
    const libraryBackIconHandler = () => {
      history.push('/');
    };

    // widget creators
    dispatch(
      createWidget('library-back', 'Back', backIcon, libraryBackIconHandler)
    );

    // clean up the widgets when we go away
    return () => {
      dispatch(removeWidgets(['library-back']));
    };
  }, [dispatch, history]);

  /**
   * SIDEBAR
   */
  useEffect(() => {
    const widgets = process.env.REACT_APP_SHOW_FEEDBACK
      ? ['empty', 'feedback', 'library-back']
      : ['empty', 'library-back'];

    dispatch(enableWidgets(widgets));
    dispatch(setTheme('none'));
  }, [dispatch]);

  return (
    <>
      <SideBar />
      <LibraryView assets={assets} />
    </>
  );
};

export default withRouter(LibraryContainer);
