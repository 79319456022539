// @flow

import React from 'react';

import './TierTwoItem.scss';
import MenuItem from '../MenuItem/MenuItem';

type Props = {
  active: boolean,
  copy: string,
  id: string,
  onClick: (id: string) => void
};

/**
 * Outputs a MenuItem with a simple wrapper element to apply unique styles.
 * The props are exactly the same as the generic MenuItem, and are simply
 * passed on down.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const TierTwoItem = (props: Props) => {
  const { active, copy, id, onClick } = props;

  return (
    <div className="tier-two-item">
      <MenuItem active={active} copy={copy} id={id} onClick={onClick} />
    </div>
  );
};

export default TierTwoItem;
