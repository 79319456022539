// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const VirtualPlantIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 102 105.1"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Virtual Plant Icon</title>

      <g>
        <path d="M78.6,25.4c-6.2,0-12.4-2-16-5.6c-3.2,0.2-6.1-0.1-8.8-0.3c-5.7-0.5-10.7-0.9-16.6,2.5l-2.8,1.6l1.4-2.9 C46.3-1.4,60.3,2.5,71.6,5.5c7.7,2.1,13.7,3.8,17.6-2l2.2-3.3l-0.4,4c-0.4,3.9-2.4,7.5-5.5,9.8c4.2,0.4,7.8,0.1,10.3-2.4L98,9.5 l-0.4,3c-0.8,5.6-4.6,9.7-10.8,11.7C84.1,25,81.4,25.4,78.6,25.4z M63.4,17.8l0.3,0.3c4.6,4.8,14.6,6.6,22.4,4.2 c2.7-0.9,7-2.9,8.8-7.5c-3.5,1.8-7.9,1.5-12.5,0.9l-3.4-0.4l3.1-1.5c2.7-1.3,4.9-3.5,6-6.2c-4.6,3.3-10.6,1.6-17-0.1 C60.5,4.6,48.5,1.3,38.9,19c5.3-2.2,10.1-1.8,15-1.4c2.8,0.2,5.8,0.5,9,0.3L63.4,17.8z" />
      </g>
      <g>
        <path d="M102,105.1H0v-15h3.8l2.7-43.1h16.6l2.2,23.9l2.1-39h17.6l2.7,33.5l23.6-9.1l3.8,9L98.9,59v31.1h3.1V105.1z M2,103.1h98 v-11h-3.1V61.6L74,67.6l-3.7-8.8L46,68.2l-2.8-34.3H29.3l-2.7,50.8l-2,0l-3.3-35.7H8.4L5.7,92.1H2V103.1z M89.7,88H77.4V71.6h12.3 L89.7,88z M79.4,86h8.3l0-12.4h-8.3V86z M67.4,88H55.1V71.6h12.3V88z M57.1,86h8.3V73.6h-8.3V86z" />
      </g>
    </svg>
  );
};

export default VirtualPlantIcon;
