// @flow

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtBar from 'features/ButtBar/ButtBar/ButtBar';

import { activateButt, hoverButt, dehoverButt } from 'store/butt/actions';
import {
  getActiveButt,
  getButts,
  getButtBarActive,
  getButtBarEnabled,
  getHoverButt
} from 'store/butt/selectors';
import { getPlantsEnabled } from 'store/plant/selectors';
import { showModal } from 'store/plant/actions';

type Props = {};

/**
 * Outputs ButtBar, while using business logic to dispatch
 * information to store and view store states
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const ButtBarContainer = (props: Props) => {
  const dispatch = useDispatch();
  // view current store state for Butts array
  const seeButts = useSelector(getButts);
  // view current store state for ButtBar
  const isButtBarActive = useSelector(getButtBarActive);
  const isButtBarEnabled = useSelector(getButtBarEnabled);
  const activeButt = useSelector(getActiveButt);
  const hoveredButt = useSelector(getHoverButt);
  const plantsActive = useSelector(getPlantsEnabled);

  const showPlantModal = () => dispatch(showModal());

  function handleOnClick(id) {
    if (process.env.REACT_APP_IS_PUBLIC === '1') {
      if (plantsActive) {
        dispatch(activateButt(id));
      } else {
        showPlantModal();
      }
    } else {
      dispatch(activateButt(id));
    }
  }

  function handleOnMouseEnter(id) {
    dispatch(hoverButt(id));
  }

  function handleOnMouseLeave(id) {
    dispatch(dehoverButt());
  }

  return (
    <ButtBar
      active={isButtBarActive}
      enabled={isButtBarEnabled}
      butts={seeButts}
      activeButt={activeButt}
      hoverButt={hoveredButt}
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    />
  );
};

export default ButtBarContainer;
