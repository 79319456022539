// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const TrainingIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 24.9"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Training Icon</title>

      <circle cx="10.8" cy="7.3" r="3.5" />
      <path d="M24.1,20c-0.1,0.3-0.4,0.5-0.7,0.5c-0.4,0-0.8-0.3-0.8-0.8c0,0,0,0,0,0c0-0.2,0-0.3,0.1-0.5l7.1-10.5L24.1,20z" />
      <path d="M24.7,20.3c-0.3,0.5-0.8,0.9-1.4,0.9c-0.8,0-1.5-0.7-1.5-1.5c0-0.3,0.1-0.6,0.3-0.8l0.5-0.9l-2.5-0.3c0,0-3-3.7-3.6-4.6 s-2.4-1-2.4-1l-2,5.1l-0.5-3l1-2H9.1l0.9,2l-0.4,3.1l-2-5.2c-3.9,0.3-3.8,3.4-3.8,3.4v9.4h11.5l0.3-6.3c0.6,0.5,1.1,1.2,1.6,1.8 c0.4,0.6,1,1,1.7,1l4.1,0.4c1,0.2,2-0.4,2.2-1.4c0.1-0.4,0-0.8-0.2-1.2L24.7,20.3z" />
      <g>
        <polygon points="32,24.9 17.3,24.9 17.3,23.4 30.5,23.4 30.5,1.5 1.5,1.5 1.5,24.9 0,24.9 0,0 32,0  " />
      </g>
    </svg>
  );
};

export default TrainingIcon;
