// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { uniquelyConcatArrays } from 'helpers';
import FilteredList from './FilteredList/FilteredList';
import { setActiveFilter, setAllItems } from 'store/filter/actions';
import {
  getActiveFilterId,
  getActiveFilterItems
} from 'store/filter/selectors';

import type { Filter } from 'features/FilteredList/types';

type Props = {
  filters: Filter[],
  id: string
};

/**
 * The core business logic behind the FilteredList featured.
 * Accepts a list of Filter objects to be utilized.
 * Connects with the redux store to manage its active filter
 * and store all items array so these values can be
 * access by other containers
 *
 * @author Todd Miller <github.com/Toddses>
 */
const FilteredListContainer = (props: Props) => {
  const { filters, id } = props;
  // basic hooks
  const dispatch = useDispatch();
  // redux selectors
  const activeFilterId = useSelector(getActiveFilterId(id));
  const activeFilterItems = useSelector(getActiveFilterItems(id));
  // local state
  const [selectorOpen, setSelectorOpen] = useState(
    activeFilterId ? true : false
  );

  // compile and set all items
  useEffect(() => {
    if (!filters || !filters.length) return;
    dispatch(setAllItems(id, uniquelyConcatArrays(filters)));
  }, [dispatch, filters, id]);

  // get the item array associated with the given filter id
  const getFilterItems = (filterId: string) => {
    const activeFilter: Filter | void = filters.find(
      (filter) => filter.id === filterId
    );

    return activeFilter ? activeFilter.items : ([]: string[]);
  };

  // additional functions
  const onFilterClick = (filterId: string) => {
    if (filterId)
      dispatch(setActiveFilter(id, filterId, getFilterItems(filterId)));
    else dispatch(setActiveFilter(id, '', []));
  };

  const toggleSelectorOpen = () => {
    setSelectorOpen(!selectorOpen);
  };

  return (
    <FilteredList
      activeFilter={activeFilterId}
      filters={filters}
      onFilterClick={onFilterClick}
      onSelectorClick={toggleSelectorOpen}
      products={activeFilterItems}
      showPrimary={activeFilterId !== ''}
      selectorOpen={selectorOpen}
    />
  );
};

export default FilteredListContainer;
