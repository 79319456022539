// @flow

import React from 'react';

import './DrawerFooter.scss';

import type { Node } from 'react';

type Props = {
  children?: Node
};

/**
 * Outputs its children and provides a wrapper element to apply styles
 * @author Todd Miller <github.com/Toddses>
 */
const DrawerFooter = (props: Props) => {
  return <div id="drawer-footer">{props.children}</div>;
};

export default DrawerFooter;
