// @flow

import React from 'react';

import './TierOneItem.scss';
import MenuItem from '../MenuItem/MenuItem';

type Props = {
  active: boolean,
  copy: string,
  id: string,
  path?: string,
  onClick: (id: string) => void,
  onMouseEnter: (id: string) => void
};

/**
 * Outputs a MenuItem with a simple wrapper element to apply unique styles.
 * The props are exactly the same as the generic MenuItem, and are simply
 * passed on down.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const TierOneItem = (props: Props) => {
  const { active, copy, id, path = '', onClick, onMouseEnter } = props;

  const handleOnMouseEnter = () => {
    onMouseEnter(id);
  };

  return (
    <div className="tier-one-item" onMouseEnter={handleOnMouseEnter}>
      <MenuItem
        active={active}
        copy={copy}
        id={id}
        path={path}
        onClick={onClick}
      />
    </div>
  );
};

export default TierOneItem;
