// @flow

import React from 'react';

import './VideoCard.scss';
import CirclePlayIcon from 'icons/CirclePlayIcon';
import SimpleCard from '../SimpleCard';

type Props = {
  image: string,
  path: string,
  title: string
};

/**
 * Uses SimpleCard composition to output a card with a "play" button
 * @author Todd Miller <github.com/Toddses>
 */
const VideoCard = (props: Props) => {
  const { image, path, title } = props;
  const icon = <CirclePlayIcon iconTitle="video-icon" />;

  return (
    <SimpleCard
      image={image}
      overlayCopy="Play"
      button={icon}
      title={title}
      path={path}
      newTab={true}
    />
  );
};

export default VideoCard;
