// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const UserInstructionsIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 29.9"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>User Instructions Icon</title>

      <circle cx="14.5" cy="11.9" r="2" />
      <polygon points="15.5,23 15.6,15.7 10.9,15.7 10.9,17.7 13.5,17.7 13.5,23 10.9,23 10.9,25 18.1,25 18.1,23 " />
      <path d="M30.4,7.3h-1.2v6.1h1.2c0.9,0,1.6-0.8,1.7-1.7V9C32,8.1,31.3,7.3,30.4,7.3z" />
      <path d="M30.4,14.8h-1.2v6.1h1.2c0.9,0,1.6-0.8,1.7-1.7v-2.8C32,15.6,31.3,14.8,30.4,14.8z" />
      <path d="M30.4,22.4h-1.2v6.1h1.2c0.9,0,1.6-0.8,1.7-1.7v-2.8C32,23.2,31.3,22.4,30.4,22.4z" />
      <path d="M3.7,1.5h23.8V0H3.5C1.6,0,0,1.7,0,3.6v22.3c0,2.2,1.8,4,4,4h23.4V7.2V6.5V5.7H3.7c-1.2,0-2.1-0.9-2.2-2.1v0 C1.5,2.5,2.4,1.5,3.7,1.5z M3.6,7.2C3.6,7.2,3.6,7.2,3.6,7.2l22.4,0v21.2H4c-1.4,0-2.5-1.1-2.5-2.5V6.5C2.1,7,2.8,7.2,3.6,7.2z" />
    </svg>
  );
};

export default UserInstructionsIcon;
