// @flow

import React from 'react';
import { useDispatch } from 'react-redux';

import './PlantView.scss';
import AssetCards from 'components/AssetCards/AssetCards';
import FullScreenBackground from 'components/FullScreenBackground/FullScreenBackground';
import Hotspot from 'components/Hotspot/Hotspot';
import Panel from 'components/Panel/Panel';
import SimpleCardList from 'components/SimpleCardList/SimpleCardList';
import Title from 'components/Title/Title';
import Modal from './Modal/Modal';

import { deactivateTransition } from 'store/transition/actions';

import type { Asset, Plant, Unit } from 'types';

type Props = {
  activeInfo: boolean,
  closePlantWideInfo: () => void,
  hoveredButt: string,
  plant: Plant,
  plantWideAssets: Asset[],
  units: Unit[],
  onClick: (string) => void,
  onMouseEnter: (string) => void,
  onMouseLeave: () => void,
  closePlantModal: () => void,
  showPlantModal: () => void,
  showModal: boolean,
  plantsEnabled: boolean
};

/*
 * Gathers image, plant title, plant information, and
 * hotspots inside a butt Panel.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const PlantView = (props: Props) => {
  const {
    activeInfo,
    closePlantWideInfo,
    hoveredButt,
    plant,
    plantWideAssets,
    units,
    onClick,
    onMouseEnter,
    onMouseLeave,
    closePlantModal,
    showPlantModal,
    showModal,
    plantsEnabled
  } = props;
  const dispatch = useDispatch();

  const handleOverviewLoaded = () => {
    setTimeout(() => {
      dispatch(deactivateTransition());
    }, 500);
  };

  function handleHotspotClick(event) {
    if (process.env.REACT_APP_IS_PUBLIC === '1') {
      if (plantsEnabled) {
        onClick(event);
      } else {
        showPlantModal();
      }
    } else {
      onClick(event);
    }
  }

  return (
    <>
      <Modal close={closePlantModal} active={showModal} />
      <div id="plant-view">
        <Panel type="butt">
          <FullScreenBackground
            image={plant.overview}
            onLoaded={handleOverviewLoaded}
          />
          <Title copy={plant.title} theme="dark" />
          <div className={`active-info ${activeInfo ? 'is-active' : ''}`}>
            <SimpleCardList onClose={closePlantWideInfo}>
              <AssetCards assets={plantWideAssets} />
            </SimpleCardList>
          </div>
          {units.map((unit) => {
            return (
              <Hotspot
                key={unit.id}
                id={unit.id}
                active={hoveredButt === unit.id}
                disabled={hoveredButt !== '' && hoveredButt !== unit.id}
                copy={unit.summary}
                image={unit.card}
                modalHorizontal={unit.modal.horizontal}
                modalVertical={unit.modal.vertical}
                x={unit.modal.x}
                y={unit.modal.y}
                onClick={handleHotspotClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            );
          })}
        </Panel>
      </div>
    </>
  );
};

export default PlantView;
