// @flow

import { ACTIONS } from 'store/sidebar/actions';

import type { Element } from 'react';
import type { SidebarWidget as Widget } from 'features/SideBar/types';

export type SidebarState = {
  activeWidgets: Widget[],
  theme: 'light' | 'dark' | 'none',
  widgets: Widget[]
};

export type SidebarAction = {
  type: string | null,
  handler: (string | void) => void,
  copy: string,
  icon: Element<any>,
  id: string,
  ids: string[],
  theme: 'light' | 'dark' | 'none'
};

const defaultState = {
  activeWidgets: [],
  theme: 'none',
  widgets: [
    {
      active: false,
      copy: '',
      handler: () => {},
      icon: null,
      id: 'empty'
    }
  ]
};

const sidebar = (state: SidebarState = defaultState, action: SidebarAction) => {
  switch (action.type) {
    case ACTIONS.SIDEBAR.SET_THEME:
      return {
        ...state,
        theme: action.theme
      };

    case ACTIONS.WIDGET.CREATE:
      return {
        ...state,
        widgets: [
          ...state.widgets,
          {
            active: false,
            copy: action.copy,
            handler: action.handler,
            icon: action.icon,
            id: action.id
          }
        ]
      };

    case ACTIONS.WIDGET.ENABLE_ARRAY:
      return {
        ...state,
        activeWidgets: action.ids.map<Widget | void>((id: string) => {
          return state.widgets.find((widget) => {
            return widget.id === id;
          });
        }),
        widgets: state.widgets.map<Widget>((widget: Widget) => {
          return {
            ...widget,
            active: action.ids.indexOf(widget.id) !== -1
          };
        })
      };

    case ACTIONS.WIDGET.REMOVE:
      return {
        ...state,
        widgets: (state.widgets.filter((widget: Widget) => {
          return !action.ids.includes(widget.id);
        }): Widget[])
      };

    default:
      return state;
  }
};

export default sidebar;
