// @flow

import React, { useState } from 'react';

import TabbedPanel from './TabbedPanel/TabbedPanel';

import type { Tab } from 'features/TabbedPanel/types';

type Props = {
  tabs: Tab[],
  title: string,
  closeTabContainer?: () => void
};

/*
 * Outputs the TabContent, establishes which widgets (and theme)
 * to implement in the sidebar.
 *
 * Holds local state for active tab.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const TabbedPanelContainer = (props: Props) => {
  const { tabs, title, closeTabContainer } = props;

  /* STATE */
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  /* STATE FUNCTIONS */
  /* changing activate tab based on user input */
  function activateTab(tabId) {
    setActiveTab(tabId);
  }

  return (
    <TabbedPanel
      activeTab={activeTab} // active tab id
      tabs={tabs} // list of tabs and the info panels, may be built differently, TBD
      title={title}
      closeTabContainer={closeTabContainer}
      tabOnClick={activateTab} // active tab function
    />
  );
};

export default TabbedPanelContainer;
