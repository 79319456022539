// @flow

import React from 'react';

import './Drawer.scss';
import DrawerFooter from './DrawerFooter/DrawerFooter';
import DrawerHeader from './DrawerHeader/DrawerHeader';
import DrawerItem from './DrawerItem/DrawerItem';

import type { DrawerItem as Item } from '../types';

type Props = {
  active: boolean,
  activeDrawer: string,
  items: Item[],
  onClose: () => void
};

/**
 * Outputs the core Drawer elements. Conveniently splits up the
 * Drawer into three core elements - Header, DrawerItem List, and Footer
 *
 * - active determines if the Drawer should be open or not
 * - activeDrawer is the ID of the currently active DrawerItem
 * - items is the array list of DrawerItems to output
 * - onClose is the function to execute when the X button is clicked in the Header
 *
 * @author Todd Miller <github.com/Toddses>
 */
const Drawer = (props: Props) => {
  const { active, activeDrawer, items, onClose } = props;

  return (
    <div id="drawer" className={`${active ? 'is-active' : ''}`}>
      <DrawerHeader onClose={onClose} />

      <div className="drawer-items">
        {items.map((item) => (
          <DrawerItem
            key={item.id}
            active={activeDrawer === item.id}
            copy={item.copy}
            handler={item.handler}
          />
        ))}
      </div>

      <DrawerFooter>
        <small>
          <a
            href="https://www.flowserve.com/en/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read our privacy notice and manage your cookies settings
          </a>
        </small>
        <small>
          Visit our company website at{' '}
          <a
            href="https://www.flowserve.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Flowserve.com
          </a>
        </small>
        <small>© {new Date().getFullYear()} Flowserve Corporation</small>
      </DrawerFooter>
    </div>
  );
};

export default Drawer;
