// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const PlantWideInfoIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 25"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Plant Wide Information Icon</title>

      <g>
        <path d="M5.1,8.6h-2C3.1,6,5.1,4,7.6,4v2C6.2,6,5.1,7.1,5.1,8.6z" />
      </g>
      <path d="M29.6,2.2v2c0.2,0,0.4,0.2,0.4,0.4v17.3c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.2-0.5-1.2-1.1V2.4c0-1.3-1.1-2.4-2.4-2.4h-6.9 l-2.2,3.2h-3.1c-1.4-1.4-3.3-2.3-5.4-2.3C3.4,0.9,0,4.3,0,8.6v13.9C0,23.9,1.2,25,2.6,25h26.4v0c1.7-0.1,3-1.4,3-3.1V4.6 C32,3.3,30.9,2.2,29.6,2.2z M13.4,13.6l3.8,3.8l-0.7,0.7l-3.8-3.8C12.9,14.1,13.2,13.8,13.4,13.6z M7.6,2.9c3.1,0,5.6,2.5,5.6,5.6 s-2.5,5.6-5.6,5.6S2,11.7,2,8.6S4.5,2.9,7.6,2.9z M2,22.5v-8.8c1.4,1.5,3.4,2.5,5.6,2.5c1.2,0,2.3-0.3,3.4-0.8l5.5,5.5l3.5-3.5 l-5.5-5.5c0.5-1,0.8-2.1,0.8-3.3c0-1.2-0.3-2.3-0.8-3.4h2.8L19.4,2h5.9c0.2,0,0.4,0.2,0.4,0.4v19.5c0,0.4,0.1,0.8,0.2,1.1H2.6 C2.3,23,2,22.8,2,22.5z" />
    </svg>
  );
};

export default PlantWideInfoIcon;
