// @flow

import type { Dispatch, GetState } from 'types';

// ============================
// ACTION TYPES
// ============================
export const ACTIONS = {
  TRANSITION: {
    ACTIVATE: 'TRANSITION::ACTIVATE',
    CLEAR: 'TRANSITION::CLEAR',
    DEACTIVATE: 'TRANSITION::DEACTIVATE',
    RECEIVE: 'TRANSITION::RECEIVE',
    REQUEST: 'TRANSITION::REQUEST'
  }
};

// ============================
// ACTION CREATORS
// ============================
export const activateTransition = (id: string) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const transits = getState().transition.transits;

  if (!transits[id]) {
    if (process.env.REACT_APP_DEBUG_LOGGING)
      console.debug(
        `Could not activate transition '${id}', it does not exist!`
      );
    return false;
  }

  dispatch({
    type: ACTIONS.TRANSITION.ACTIVATE,
    id
  });
};

export const deactivateTransition = () => {
  return {
    type: ACTIONS.TRANSITION.DEACTIVATE
  };
};

export const clearTransitions = () => {
  return {
    type: ACTIONS.TRANSITION.CLEAR
  };
};

const receiveTransition = (id: string, src: string, transitionTo: string) => {
  return {
    type: ACTIONS.TRANSITION.RECEIVE,
    id,
    src,
    transitionTo
  };
};

const requestTransition = (id: string) => {
  return {
    type: ACTIONS.TRANSITION.REQUEST,
    id
  };
};

// ============================
// ACTION METHODS
// ============================
export const createTransition = (
  id: string,
  src: string,
  transitionTo: string
) => (dispatch: Dispatch, getState: GetState) => {
  const transits = getState().transition.transits;

  if (transits[id]) {
    if (process.env.REACT_APP_DEBUG_LOGGING)
      console.debug(`Could not create transition '${id}', it already exists!`);
    return false;
  }

  dispatch(requestTransition(id));

  loadVideo(src).then((response) => {
    dispatch(receiveTransition(id, response, transitionTo));
  });
};

async function loadVideo(src) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', src, true);
    xhr.responseType = 'blob';

    xhr.addEventListener(
      'load',
      () => {
        if (xhr.status === 200) {
          const URL = window.URL || window.webkitURL;
          const blob = URL.createObjectURL(xhr.response);
          resolve(blob);
        } else {
          reject('failed to preload');
        }
      },
      false
    );

    xhr.send();
  });
}
