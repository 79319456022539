// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const VideosIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.1 31.7"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Videos Icon</title>

      <path d="M13,27l7.9-4.2L13,18.6V27z" />
      <path d="M27.3,9.1l-11.9,0l16.7-3.3l-1-4.8l0-0.1c-0.2-0.7-0.9-1-1.5-0.9L1.1,5.6C0.4,5.7,0,6.2,0,6.8v21.6c0,1.8,1.4,3.2,3.2,3.2 h25.3c1.8,0,3.2-1.4,3.2-3.2V9.6l-0.1-0.2C31.4,9.1,31.4,9.1,27.3,9.1z M30.2,15.2h-4.7l4.7-4.7V15.2z M28.8,10.6l-4.7,4.7h-7.9 l4.7-4.7C24.1,10.6,27,10.6,28.8,10.6z M19.5,10.6l-4.7,4.7H6.9l4.7-4.7C14.1,10.6,16.9,10.6,19.5,10.6z M10.9,8.4L7.1,6l7.7-1.5 l3.8,2.5L10.9,8.4z M16.2,4.2l7.7-1.5l3.8,2.5L20,6.6L16.2,4.2z M29.7,1.5l0.6,3.1l-1.2,0.2l-3.8-2.5L29.7,1.5z M5.7,6.2l3.8,2.4 L7.5,9.1l0.1,1.5c0.8,0,1.6,0,2.5,0l-4.7,4.7h-4l0-8.2L5.7,6.2z M28.5,30.2H3.2c-0.9,0-1.7-0.8-1.7-1.7l0-12.2h28.7v12.2 C30.2,29.4,29.5,30.2,28.5,30.2z" />
    </svg>
  );
};

export default VideosIcon;
