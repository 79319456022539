// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const CirclePlayIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Play Icon</title>

      <g>
        <path d="M20.5,41C9.2,41,0,31.8,0,20.5S9.2,0,20.5,0S41,9.2,41,20.5S31.8,41,20.5,41z M20.5,1C9.7,1,1,9.7,1,20.5 C1,31.3,9.7,40,20.5,40C31.3,40,40,31.3,40,20.5C40,9.7,31.3,1,20.5,1z" />
      </g>
      <g>
        <path d="M12.8,31.3V9.2l20.9,11L12.8,31.3z M14.3,11.7v17.1l16.1-8.5L14.3,11.7z" />
      </g>
    </svg>
  );
};

export default CirclePlayIcon;
