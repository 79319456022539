// @flow

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './FilterSelector.scss';
import ChevronDownIcon from 'icons/ChevronDownIcon';
import ClearFiltersIcon from 'icons/ClearFiltersIcon';

import type { Filter } from 'features/FilteredList/types';

type Props = {
  activeFilter: string,
  filters: Filter[],
  onFilterClick: (string) => void,
  onSelectorClick: () => void,
  selectorOpen: boolean
};

/**
 * OUtputs the selector box based on the given props
 * - String id of the active filter
 * - the list of Filter objects to output in the selector
 * - the function to execute when a filter is clicked on
 *
 * Uses local state to manage its open/closed state
 *
 * @author Todd Miller <github.com/Toddses>
 */
const FilterSelector = (props: Props) => {
  const {
    activeFilter,
    filters,
    onFilterClick,
    onSelectorClick,
    selectorOpen
  } = props;

  const filterClickHandler = (id: string) => {
    onFilterClick(id);
  };

  return (
    <div id="filter-selector-container">
      <div
        className={`selector ${selectorOpen ? 'is-open' : ''}`}
        onClick={onSelectorClick}
      >
        <small>Application Types</small>
        <ChevronDownIcon iconTitle="filter-selector-chevron" />
      </div>

      <div className={`options ${selectorOpen ? 'is-open' : ''}`}>
        <Scrollbars
          autoHide={true}
          hideTracksWhenNotNeeded={true}
          autoHeight
          autoHeightMax={500}
        >
          <div className="options-wrapper">
            {activeFilter ? (
              <div
                className="clear-filters w-bold uppercase"
                onClick={() => {
                  filterClickHandler('');
                }}
              >
                <ClearFiltersIcon iconTitle="clear-filters" />
                <small>Clear Filters</small>
              </div>
            ) : (
              ''
            )}

            {filters.map((filter) => {
              return (
                <div
                  key={filter.id}
                  className={`filter ${
                    activeFilter === filter.id ? 'is-active' : ''
                  }`}
                  onClick={() => {
                    filterClickHandler(filter.id);
                  }}
                >
                  <small>{filter.title}</small>
                </div>
              );
            })}
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default FilterSelector;
