// @flow
import React, { useRef } from 'react';
import CustomScrollbar from 'react-custom-scrollbars';

import CloseIcon from 'icons/CloseIcon';

import './Modal.scss';

type Props = {
  active: boolean,
  close: () => void
};

/**
 * Modal to display the form which enables users to view the plant details on the public
 * version only.
 *
 * @param props
 * @returns {*}
 * @constructor
 *
 * @author Devon Taylor <https://github.com/taylord93>
 */

const Modal = (props: Props) => {
  const { active, close } = props;

  const scrollbars = useRef<CustomScrollbar>(null);

  const onLoad = () => {
    scrollbars.current.scrollTop(0);
  };

  return (
    <div className={`plant-modal ${active ? 'is-active' : ''}`}>
      <div className={`overlay`} onClick={close} />
      <div className={`modal-wrapper`}>
        <div className="close-button" onClick={close}>
          <CloseIcon iconTitle="close-icon" />
        </div>

        <div className={`banner`}>
          <div className={`heading`}>VirtualPlant Operational Units</div>
          <h4>A real-life view of Flowserve's products at work</h4>
        </div>

        <div className={`content`}>
          <CustomScrollbar ref={scrollbars}>
            <div className={`text`}>
              <b>By accessing each unit, you will discover:</b>
              <ul>
                <li>Process flow diagrams</li>
                <li>Functional properties descriptions</li>
                <li>Proven solutions-oriented products</li>
                <li>Technical and commercial resources</li>
              </ul>
              <b>
                Complete the form below to access all of the VirtualPlant units.
              </b>
            </div>

            <iframe
              src={process.env.REACT_APP_MODAL_FORM_URL}
              title="plant-form"
              frameBorder="0"
              onLoad={onLoad}
            />
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};

export default Modal;
