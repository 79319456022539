// @flow

import React from 'react';

import './Hamburger.scss';
import { useThemeContext } from '../ThemeContext';
import HamburgerIcon from 'icons/HamburgerIcon';

type Props = {
  onClick: () => void
};

/**
 * Specialized Widget for the Hamburger menu. Executes the
 * given function on Click.
 * Utilize the ThemeContext to apply styles based on the current theme
 *
 * @author Todd Miller <github.com/Toddses>
 */
const Hamburger = (props: Props) => {
  const { onClick } = props;
  const theme = useThemeContext();

  return (
    <div id="hamburger-container" className={theme.theme} onClick={onClick}>
      <HamburgerIcon iconTitle="hamburger=menu-icon" />
    </div>
  );
};

export default Hamburger;
