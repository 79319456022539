// @flow

import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import { getSidebarTheme } from 'store/sidebar/selectors';

import type { Node } from 'react';

type Theme = {
  theme: 'light' | 'dark' | 'none'
};

type Props = {
  children?: Node
};

/**
 * Build a Context for the theme style, so thatwe don't have to pass theme
 * around to a bunch ofSideBar child components. Any component who needs
 * the theme value can just pull it from the Context.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const ThemeContext = createContext<Theme>({ theme: 'none' });

export const ThemeProvider = (props: Props) => {
  const theme = useSelector(getSidebarTheme);

  return (
    <ThemeContext.Provider value={{ theme: theme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

// this provides a hook that can be utilized to access the Context
// usage:
// const theme = useThemeContext();
export const useThemeContext = () => useContext(ThemeContext);
