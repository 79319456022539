// @flow

import { Get } from 'api';
import { fetchProduct } from 'store/product/actions';

import type { DamUnitsResponse, Dispatch, GetState, Unit } from 'types';

// ============================
// ACTION TYPES
// ============================
export const ACTIONS = {
  UNIT: {
    REQUEST: 'UNIT::REQUEST',
    RECEIVE: 'UNIT::RECEIVE',
    ERROR: 'UNIT::ERROR'
  }
};

// ============================
// ACTION CREATORS
// ============================
function _errorUnits(plantId: string, error: { message: string }) {
  return {
    type: ACTIONS.UNIT.ERROR,
    plantId
  };
}

function _receiveUnits(plantId: string, units: Unit[]) {
  return {
    type: ACTIONS.UNIT.RECEIVE,
    plantId,
    units
  };
}

function _requestUnits(plantId: string) {
  return {
    type: ACTIONS.UNIT.REQUEST,
    plantId
  };
}

// ============================
// ACTION METHODS
// ============================
export const fetchUnits = (plantId: string) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (getState().unit.hasOwnProperty(plantId)) return false;

  dispatch(_requestUnits(plantId));

  Get(`virtualplants/${plantId}/units`).then((response: DamUnitsResponse) => {
    if (response.status !== 200) {
      dispatch(_errorUnits(plantId, response.data));
      return false;
    }

    const units = [];

    response.data.forEach((unit) => {
      const products = [];

      unit.products.forEach((product) => {
        products.push({
          id: product.id,
          title: product.title,
          products: product.products
            ? product.products.map((id) => {
                dispatch(fetchProduct(id));
                return id;
              })
            : [],
          applications: product.applications
            ? product.applications.map((app) => {
                app.products.forEach((id) => {
                  dispatch(fetchProduct(id));
                });

                return {
                  id: app.id,
                  title: app.title,
                  items: app.products
                };
              })
            : []
        });
      });

      units.push({
        id: unit.id,
        title: unit.title,
        overview: unit.overview.url,
        card: unit.thumb.url,
        transitions: {
          in: unit.transitions.in.url,
          out: unit.transitions.out.url
        },
        summary: unit.summary,
        description: unit.description,
        modal: {
          x: unit.x,
          y: unit.y,
          vertical: unit.modal_vert || 'top',
          horizontal: unit.modal_hor
        },
        content: unit.content,
        products: products
      });
    });

    dispatch(_receiveUnits(plantId, units));
  });
};
