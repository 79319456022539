//@flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterHistory, withRouter } from 'react-router-dom';

import ImageView from 'components/ImageView/ImageView';
import ProductView from 'components/ProductView/ProductView';
import SideBar from 'features/SideBar';

import BackIcon from 'icons/ArrowLeftIcon';
import ImagesIcon from 'icons/ImagesIcon';
import MarketingLitIcon from 'icons/MarketingLitIcon';
import TechnicalLitIcon from 'icons/TechnicalLitIcon';
import TrainingIcon from 'icons/TrainingIcon';
import InstructionsIcon from 'icons/UserInstructionsIcon';
import VideosIcon from 'icons/VideosIcon';

import { getAssets } from 'store/asset/selectors';
import { setCurrent } from 'store/current/actions';
import { getCurrent } from 'store/current/selectors';
import { getActiveFilterItems } from 'store/filter/selectors';
import { fetchPlants } from 'store/plant/actions';
import { getIsFetching as getIsPlantsFetching } from 'store/plant/selectors';
import { fetchProduct } from 'store/product/actions';
import { getHasError, getProducts } from 'store/product/selectors';
import {
  createWidget,
  enableWidgets,
  removeWidgets,
  setTheme
} from 'store/sidebar/actions';

import type { matchPath } from 'react-router-dom';

type Props = {
  history: RouterHistory,
  match: matchPath
};

/**
 * Container for Product Image, Simple Cards, and
 * Literature Selector.
 *
 * Includes routing, LitType information for populating LiteratureSelector
 * with static information, and local state for activity.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const ProductContainer = (props: Props) => {
  const { history, match } = props;
  const dispatch = useDispatch();
  // route params
  const id: string = match.params.id;
  // selectors
  const product = useSelector(getProducts(id));
  const hasError = useSelector(getHasError(id));
  const isPlantsFetching = useSelector(getIsPlantsFetching);
  const unitActivePlant = useSelector(getCurrent('unitActivePlant'));
  const unitActiveUnit = useSelector(getCurrent('unitActiveUnit'));
  const unitActivePanel = useSelector(getCurrent('unitActivePanel'));
  const unitProducts = useSelector(getActiveFilterItems(unitActivePanel));
  // local state
  const [pagers, setPagers] = useState([]);
  const [activeAsset, setActiveAsset] = useState('');
  // icons
  const imagesIcon = <ImagesIcon iconTitle="lit-image-icon" />;
  const marketingIcon = <MarketingLitIcon iconTitle="lit-marketing-icon" />;
  const technicalIcon = <TechnicalLitIcon iconTitle="lit-technical-icon" />;
  const trainingIcon = <TrainingIcon iconTitle="lit-training-icon" />;
  const instructionsIcon = <InstructionsIcon iconTitle="lit-instruct-icon" />;
  const videosIcon = <VideosIcon iconTitle="lit-videos-icon" />;

  /**
   * FETCH
   */
  useEffect(() => {
    dispatch(fetchProduct(id));
    dispatch(fetchPlants());
  }, [dispatch, id]);

  /**
   * LOADING SCREEN
   */
  useEffect(() => {
    if (hasError) dispatch(setCurrent('LoadingActive', false));
    else if (!product.id || isPlantsFetching)
      dispatch(setCurrent('LoadingActive', true));
    else dispatch(setCurrent('LoadingActive', false));
  }, [dispatch, product, isPlantsFetching, hasError]);

  /**
   * LIT PANELS
   */
  const litTypes = [
    {
      id: 'marketing',
      icon: marketingIcon,
      title: 'Marketing Literature',
      disabled: product.id && product.assets.marketing.length === 0,
      assets: useSelector(getAssets(product.id ? product.assets.marketing : []))
    },
    {
      id: 'technical',
      icon: technicalIcon,
      title: 'Technical Literature',
      disabled: product.id && product.assets.technical.length === 0,
      assets: useSelector(getAssets(product.id ? product.assets.technical : []))
    },
    {
      id: 'videos',
      icon: videosIcon,
      title: 'Videos',
      disabled: product.id && product.assets.videos.length === 0,
      assets: useSelector(getAssets(product.id ? product.assets.videos : []))
    },
    {
      id: 'images',
      icon: imagesIcon,
      title: 'Images',
      disabled: product.id && product.assets.images.length === 0,
      assets: useSelector(getAssets(product.id ? product.assets.images : []))
    },
    {
      id: 'instructions',
      icon: instructionsIcon,
      title: 'User Instructions',
      disabled: product.id && product.assets.instructions.length === 0,
      assets: useSelector(
        getAssets(product.id ? product.assets.instructions : [])
      )
    },
    {
      id: 'training',
      icon: trainingIcon,
      title: 'Training',
      disabled: product.id && product.assets.training.length === 0,
      assets: useSelector(getAssets(product.id ? product.assets.training : []))
    }
  ];

  // set up the pager
  useEffect(() => {
    if (!unitProducts.length) return;

    const len = unitProducts.length;

    if (len <= 1) {
      setPagers([]);
      return;
    }

    const index = unitProducts.indexOf(id);
    const next = (index + 1) % len;
    const prev = (index + len - 1) % len;

    setPagers([
      {
        id: unitProducts[prev],
        type: 'previous'
      },
      {
        id: unitProducts[next],
        type: 'next'
      }
    ]);
  }, [dispatch, unitProducts, id]);

  /*
   * SIDEBAR
   */
  // theming
  useEffect(() => {
    dispatch(setTheme('light'));
  }, [dispatch]);

  // manage widgets
  useEffect(() => {
    // widget icons
    const backIcon = <BackIcon iconTitle="unit-back-icon" />;

    // widget handlers
    const productBackIconHandler = () => {
      dispatch(setCurrent('LoadingActive', true));
      history.push(`/plants/${unitActivePlant}/${unitActiveUnit}`);
    };

    // widget creators
    dispatch(
      createWidget('product-back', 'Back', backIcon, productBackIconHandler)
    );

    // clean up the widgets when we go away
    return () => {
      dispatch(removeWidgets(['product-back']));
    };
  }, [dispatch, history, unitActivePlant, unitActiveUnit]);

  // manage widget activation
  useEffect(() => {
    if (unitActivePanel) {
      const widgets = process.env.REACT_APP_SHOW_FEEDBACK
        ? ['empty', 'feedback', 'product-back']
        : ['empty', 'product-back'];

      dispatch(enableWidgets(widgets));
    } else {
      const widgets = process.env.REACT_APP_SHOW_FEEDBACK
        ? ['empty', 'feedback']
        : ['empty'];

      dispatch(enableWidgets(widgets));
    }
  }, [dispatch, unitActivePanel]);

  /*
   * FUNCTIONS
   */

  /**
   * handles LiteratureType active prop state with selector when clicked,
   * extends to SimpleCardList
   */
  function activateAsset(assetId) {
    setActiveAsset(assetId);
  }

  /**
   * handles removing value from active state when SimpleCardList close button
   * is clicked, extends to LiteratureType
   */
  function deactivateAsset() {
    setActiveAsset('');
  }

  /**
   * handles navigating after a pager is clicked
   */
  function handlePagerOnClick(id) {
    dispatch(setCurrent('LoadingActive', true));
    deactivateAsset();
    history.push(id);
  }

  return (
    <>
      <SideBar />

      {product.productType === 'process_flow_diagrams' ? (
        <ImageView title={product.title} image={product.image} />
      ) : (
        <ProductView
          activeLitType={activeAsset}
          hasError={hasError}
          litType={litTypes}
          pagerInfo={pagers}
          product={product}
          deactivateAsset={deactivateAsset}
          pagerOnClick={handlePagerOnClick}
          selectorOnClick={activateAsset}
        />
      )}
    </>
  );
};

export default withRouter(ProductContainer);
