// @flow

import React from 'react';
import { useSelector } from 'react-redux';
import { RouterHistory, withRouter } from 'react-router-dom';

import Transitions from './Transitions/Transitions';
import { getTransitions } from 'store/transition/selectors';

type Props = {
  history: RouterHistory
};

/**
 * Sets up the transitions (here, by fetching from the store)
 * and outputs the Transitions container.
 *
 * navigate() provides a mechanism for routing the user, currently
 * used to navigate after the transition video finishes playing.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const TransitionsContainer = (props: Props) => {
  const transits = useSelector(getTransitions);

  const navigate = (id: string) => {
    props.history.push(transits[id].transitionTo);
  };

  return <Transitions onEnded={navigate} transits={transits} />;
};

export default withRouter(TransitionsContainer);
