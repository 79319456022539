// @flow

import React from 'react';

import './ScrollIndicator.scss';
import ArrowsVertIcon from 'icons/ArrowsVertIcon';

type Props = {};

const ScrollIndicator = (props: Props) => {
  return (
    <div id="scroll-indicator">
      <div className="semi-circle-wrap">
        <div className="semi-circle" />
      </div>
      <div className="inner">
        <ArrowsVertIcon iconTitle="scroll-arrow-icon" />
        <div className="copy w-bold">scroll</div>
      </div>
    </div>
  );
};

export default ScrollIndicator;
