// @flow

import { ACTIONS } from './actions';

import type { Transition } from 'features/Transitions/types';

export type TransitionState = {
  activeTransit: string,
  transits: {
    [id: string]: Transition
  }
};

export type TransitionAction = {
  type: string | null,
  id: string,
  src: string,
  transitionTo: string
};

const defaultState = {
  activeTransit: '',
  transits: {}
};

// transitions reducer
const transition = (
  state: TransitionState = defaultState,
  action: TransitionAction
) => {
  switch (action.type) {
    case ACTIONS.TRANSITION.ACTIVATE:
      return {
        ...state,
        activeTransit: action.id,
        transits: {
          ...state.transits,
          [action.id]: {
            ...state.transits[action.id],
            active: true
          }
        }
      };

    case ACTIONS.TRANSITION.DEACTIVATE:
      return {
        ...state,
        activeTransit: '',
        transits: {
          ...state.transits,
          [state.activeTransit]: {
            ...state.transits[state.activeTransit],
            active: false
          }
        }
      };

    case ACTIONS.TRANSITION.CLEAR:
      return {
        ...state,
        activeTransit: '',
        transits: {}
      };

    case ACTIONS.TRANSITION.RECEIVE:
      return {
        ...state,
        transits: {
          ...state.transits,
          [action.id]: {
            active: false,
            isFetching: false,
            src: action.src,
            transitionTo: action.transitionTo
          }
        }
      };

    case ACTIONS.TRANSITION.REQUEST:
      return {
        ...state,
        transits: {
          ...state.transits,
          [action.id]: {
            isFetching: true
          }
        }
      };

    default:
      return state;
  }
};

export default transition;
