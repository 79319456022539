// @flow

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouterHistory } from 'react-router-dom';

import LandingView from 'components/LandingView/LandingView';
import LibraryIcon from 'icons/LibraryIcon';
import VirtualPlantIcon from 'icons/VirtualPlantIcon';

import { setCurrent } from 'store/current/actions';

type Props = {
  history: RouterHistory
};

const LandingContainer = (props: Props) => {
  const { history } = props;
  const dispatch = useDispatch();

  const plantIcon = <VirtualPlantIcon iconTitle="landing-plant-icon" />;
  const libraryIcon = <LibraryIcon iconTitle="landing-library-icon" />;

  /* VARIABLES */
  const intro =
    'VirtualPlant is an innovative way to browse product solutions by specific plant type and process area. All the recommendations are built on actual use cases from our extensive global customer base.';
  const title = 'Welcome to the Flowserve Virtual Plant';

  const landingOptions = [
    {
      id: 'plants',
      button: 'Step Inside',
      icon: plantIcon,
      title: 'Plant Selection',
      body:
        'Experience 3D models of plants in several key industrial applications.'
    },
    {
      id: 'library',
      button: 'Learn More',
      icon: libraryIcon,
      title: 'Library',
      body:
        'Find literature detailing Flowserve products in key industry capacities.'
    }
  ];

  useEffect(() => {
    dispatch(setCurrent('LoadingActive', false));
  }, [dispatch]);

  /**
   * handles navigating after a pager is clicked
   */
  function handleCTAClick(id) {
    dispatch(setCurrent('LoadingActive', true));
    history.push(id);
  }

  return (
    <LandingView
      intro={intro}
      landingOptions={landingOptions}
      title={title}
      ctaClick={handleCTAClick}
    />
  );
};

export default LandingContainer;
