// @flow

import type { Asset, DamAsset, Dispatch, GetState } from 'types';

import defaultCard from 'images/default-card.jpg';

// ============================
// ACTION TYPES
// ============================

export const ACTIONS = {
  ASSET: {
    RECEIVE: 'ASSET::RECEIVE'
  }
};

// ============================
// ACTION CREATORS
// ============================
function _receiveAsset(asset: Asset) {
  return {
    type: ACTIONS.ASSET.RECEIVE,
    asset
  };
}

// ============================
// ACTION METHODS
// ============================
export const createAsset = (asset: DamAsset) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (getState().asset.hasOwnProperty(asset.id)) return false;

  const files = [];

  asset.files.forEach((file) => {
    files.push({
      language: file.language,
      format: file.format || '',
      file: file.file.url
    });
  });

  dispatch(
    _receiveAsset({
      id: asset.id,
      type: asset.type,
      title: asset.title,
      description: asset.description || '',
      card: asset.vpcard ? asset.vpcard.url : defaultCard,
      literatureNumber: asset.literature_number || '',
      literatureType: asset.literature_type || '',
      files: files
    })
  );
};
