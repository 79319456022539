// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const CircleImageIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Image Icon</title>

      <g>
        <path d="M20.5,41C9.2,41,0,31.8,0,20.5S9.2,0,20.5,0S41,9.2,41,20.5S31.8,41,20.5,41z M20.5,1C9.7,1,1,9.7,1,20.5S9.7,40,20.5,40 S40,31.3,40,20.5S31.3,1,20.5,1z" />
      </g>
      <polygon points="22.4,17.6 19.2,17.6 19.2,14.4 17.7,14.4 17.7,17.6 14.4,17.6 14.4,19.1 17.7,19.1 17.7,22.4 19.2,22.4 19.2,19.1  22.4,19.1 " />
      <path d="M31.4,30.4l-6.1-6.1c1.4-1.6,2.2-3.7,2.2-5.9c0-5-4.1-9.1-9.1-9.1s-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1c2.2,0,4.3-0.8,5.9-2.1 l6.1,6.1L31.4,30.4z M18.4,26c-4.2,0-7.6-3.4-7.6-7.6c0-4.2,3.4-7.6,7.6-7.6c4.2,0,7.7,3.4,7.6,7.6C26,22.6,22.6,26,18.4,26z" />
    </svg>
  );
};

export default CircleImageIcon;
