// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const LibraryIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 102 121.4"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Library Icon</title>

      <g>
        <path d="M78.6,25.4c-6.2,0-12.4-2-16-5.6c-3.2,0.2-6.1-0.1-8.8-0.3c-5.7-0.5-10.7-0.9-16.6,2.5l-2.8,1.6l1.4-2.9 C46.3-1.4,60.3,2.5,71.6,5.5c7.7,2.1,13.7,3.8,17.6-2l2.2-3.3l-0.4,4c-0.4,3.9-2.4,7.5-5.5,9.8c4.2,0.4,7.8,0.1,10.3-2.4L98,9.5 l-0.4,3c-0.8,5.6-4.6,9.7-10.8,11.7C84.1,25,81.4,25.4,78.6,25.4z M63.4,17.8l0.3,0.3c4.6,4.8,14.6,6.6,22.4,4.2 c2.7-0.9,7-2.9,8.8-7.5c-3.5,1.8-7.9,1.5-12.5,0.9l-3.4-0.4l3.1-1.5c2.7-1.3,4.9-3.5,6-6.2c-4.6,3.3-10.6,1.6-17-0.1 C60.5,4.6,48.5,1.3,38.9,19c5.3-2.2,10.1-1.8,15-1.4c2.8,0.2,5.8,0.5,9,0.3L63.4,17.8z" />
      </g>
      <g>
        <polygon points="102,105.1 80,105.1 80,103.1 100,103.1 100,92.1 96.9,92.1 96.9,61.6 74,67.6 70.3,58.8 46,68.2 43.2,33.9 29.3,33.9 26.7,79.6 24.7,79.7 21.3,48.9 8.4,48.9 5.7,92.1 2,92.1 2,103.1 22,103.1 22,105.1 0,105.1 0,90.1 3.8,90.1 6.5,46.9 23.1,46.9 25.4,67.6 27.5,31.9 45.1,31.9 47.8,65.4 71.4,56.2 75.2,65.2 98.9,59 98.9,90.1 102,90.1  " />
      </g>
      <g>
        <path d="M32.9,95.6h-2c0-3.7,3-6.7,6.7-6.7v2C35,90.8,32.9,93,32.9,95.6z" />
      </g>
      <path d="M72.8,86l0,2c0.7,0,1.2,0.5,1.2,1.2v27.7c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4V85.7c0-1.8-1.4-3.2-3.2-3.2H55.1l-3.5,5.1 h-5.6C44,85.4,41,84,37.6,84C31.2,84,26,89.2,26,95.6v22.3c0,1.9,1.6,3.5,3.5,3.5h42.2v0c2.4-0.1,4.3-2,4.3-4.4V89.2 C76,87.4,74.6,86,72.8,86z M46.8,102.7l7,7l-1.9,1.9l-7-7C45.6,104.1,46.2,103.5,46.8,102.7z M37.6,86c5.3,0,9.6,4.3,9.6,9.6 s-4.3,9.6-9.6,9.6s-9.6-4.3-9.6-9.6S32.3,86,37.6,86z M28,117.9v-15.8c2.1,3.1,5.6,5.1,9.6,5.1c2,0,3.9-0.5,5.5-1.4l8.7,8.7l4.8-4.8 l-8.7-8.7c0.9-1.6,1.4-3.5,1.4-5.5c0-2.2-0.6-4.2-1.7-6h5.1l3.5-5.1h9.7c0.7,0,1.2,0.5,1.2,1.2v31.3c0,0.9,0.3,1.7,0.7,2.4H29.5 C28.7,119.4,28,118.7,28,117.9z" />
    </svg>
  );
};

export default LibraryIcon;
