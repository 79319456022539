// @flow

import * as React from 'react';

type Props = {
  iconTitle: string
};

function LogoIcon(props: Props) {
  const { iconTitle } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 77.8 28.6"
      aria-labelledby={iconTitle}
    >
      <title id={iconTitle}>Flowserve Website</title>
      <g>
        <g>
          <g>
            <path
              d="M37.6,6.9C34.4,3.1,28.3,0,21.4,0c-4.3,0-8.4,1.2-11.8,3.3l1.5,2c3-1.8,6.6-2.8,10.3-2.8
              c5.3,0,10.1,2.1,12.7,4.4H37.6z"
            />
          </g>
          <path
            d="M47.5,21.5c2.7,2.5,7.6,4.6,13,4.6c3.9,0,7.6-1.1,10.7-3.1l1.4,2.1c-3.5,2.2-7.6,3.5-12,3.5
            c-7,0-13.2-3.1-16.4-7.1H47.5z"
          />
          <path d="M2.6,11.3V13h2.9v2.2H2.6v4H0V9.1h5.9v2.2H2.6z" />
          <path d="M9.5,17h3.2v2.2H6.9V9.1h2.6V17z" />
          <path
            d="M23.5,14c0,3.3-2.4,5.6-5.6,5.6c-3.3,0-5.6-2.3-5.6-5.6c0-3.1,2.7-5.2,5.6-5.2C20.8,8.7,23.5,10.9,23.5,14z
             M15,14c0,1.8,1.3,3,2.9,3c1.6,0,2.9-1.3,2.9-3c0-1.4-1.3-2.7-2.9-2.7C16.3,11.3,15,12.6,15,14z"
          />
          <path
            d="M27.6,15.6L27.6,15.6l2.2-6.6h2.1l2,6.6h0l1.9-6.6h2.8l-3.3,10.2h-2.7l-1.8-5.9h0l-2,5.9h-2.7L23,9.1h2.8
            L27.6,15.6z"
          />
          <path
            d="M44.1,11.7C43.6,11.3,43,11,42.4,11c-0.5,0-1.1,0.3-1.1,0.9c0,0.6,0.7,0.8,1.2,1l0.7,0.2
            c1.4,0.4,2.5,1.1,2.5,2.8c0,1-0.2,2.1-1.1,2.7c-0.8,0.7-1.9,1-2.9,1c-1.3,0-2.5-0.4-3.6-1.2l1.1-2.1c0.7,0.6,1.4,1.1,2.4,1.1
            c0.6,0,1.3-0.3,1.3-1c0-0.7-1-1-1.6-1.2c-1.7-0.5-2.8-0.9-2.8-2.9c0-2.1,1.5-3.4,3.5-3.4c1,0,2.3,0.3,3.2,0.8L44.1,11.7z"
          />
          <path d="M49.2,11.3V13h3v2.2h-3V17h3.2v2.2h-5.8V9.1h5.8v2.2H49.2z" />
          <path
            d="M62.2,19.3h-3.3l-2.5-3.9h0v3.9h-2.6V9.1h4c2,0,3.5,1,3.5,3.1c0,1.4-0.8,2.6-2.2,2.9L62.2,19.3z M56.4,13.6
            h0.3c0.9,0,1.8-0.2,1.8-1.3c0-1.1-1-1.3-1.8-1.3h-0.3V13.6z"
          />
          <path d="M66.2,15.2l2.5-6.2h2.9l-4.4,10.2h-2.1L60.9,9.1h2.9L66.2,15.2z" />
          <path d="M74.7,11.3V13h3v2.2h-3V17h3.2v2.2H72V9.1h5.8v2.2H74.7z" />
        </g>
        <path
          d="M73.7,22.2c0-0.5,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8C74.1,23,73.7,22.7,73.7,22.2z
           M74.5,23.2c0.5,0,1-0.4,1-1c0-0.6-0.5-1-1-1c-0.5,0-1,0.4-1,1C73.5,22.8,74,23.2,74.5,23.2z M74.3,22.3h0.2l0.3,0.5H75l-0.3-0.5
          c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.4-0.3h-0.4v1.1h0.2V22.3z M74.3,22.1v-0.4h0.2c0.1,0,0.2,0,0.2,0.2c0,0.2-0.1,0.2-0.3,0.2
          H74.3z"
        />
      </g>
    </svg>
  );
}

export default LogoIcon;
