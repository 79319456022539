// @flow

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './SimpleCardList.scss';
import CloseIcon from 'icons/CloseIcon';

import type { Node } from 'react';

type Props = {
  children?: Node,
  onClose?: () => void
};

/**
 * Outputs a list of Simple Card components. You should pass in
 * your card components as children, and it must be one of the
 * SimpleCard composed components.
 *
 * Optionally pass in an onClose function to output a closer
 * icon that calls the function on click.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const SimpleCardList = (props: Props) => {
  const { children, onClose = null } = props;

  return (
    <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
      <div className="simple-card-list">
        {children}

        {onClose ? (
          <div className="closer" onClick={onClose}>
            <CloseIcon iconTitle="simple-card-close-icon" />
          </div>
        ) : (
          ''
        )}
      </div>
    </Scrollbars>
  );
};

export default SimpleCardList;
