// @flow

import React from 'react';

import './ImageCard.scss';
import CircleImageIcon from 'icons/CircleImageIcon';
import SimpleCard from '../SimpleCard';

type Props = {
  image: string,
  path: string,
  title: string
};

/**
 * Uses SimpleCard composition to output a card with an "image" button
 * @author Todd Miller <github.com/Toddses>
 */
const ImageCard = (props: Props) => {
  const { image, path, title } = props;
  const icon = <CircleImageIcon iconTitle="image-icon" />;

  return (
    <SimpleCard
      image={image}
      overlayCopy="View"
      button={icon}
      title={title}
      path={path}
      newTab={true}
    />
  );
};

export default ImageCard;
