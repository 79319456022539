// @flow

// ============================
// ACTION TYPES
// ============================
export const ACTIONS = {
  FILTER: {
    CLEAR: 'FILTER::CLEAR',
    SET: 'FILTER::SET',
    SETALL: 'FILTER::SETALL'
  }
};

// ============================
// ACTION CREATORS
// ============================
export const clearFilters = () => {
  return {
    type: ACTIONS.FILTER.CLEAR
  };
};

export const setActiveFilter = (
  id: string,
  filterId: string,
  items: string[]
) => {
  return {
    type: ACTIONS.FILTER.SET,
    id,
    filterId,
    items
  };
};

export const setAllItems = (id: string, allItems: string[]) => {
  return {
    type: ACTIONS.FILTER.SETALL,
    id,
    allItems
  };
};

// ============================
// ACTION METHODS
// ============================
