// @flow

import { combineReducers } from 'redux';

import Asset from './asset/reducers';
import Butt from './butt/reducers';
import Current from './current/reducers';
import Filter from './filter/reducers';
import Library from './library/reducers';
import Plant from './plant/reducers';
import Product from './product/reducers';
import Sidebar from './sidebar/reducers';
import Transition from './transition/reducers';
import Unit from './unit/reducers';

const reducers = {
  asset: Asset,
  butt: Butt,
  current: Current,
  filter: Filter,
  library: Library,
  plant: Plant,
  product: Product,
  sidebar: Sidebar,
  transition: Transition,
  unit: Unit
};

export type Reducers = typeof reducers;
export default combineReducers(reducers);
