// @flow

import React, { useState } from 'react';
import type { Node, Element } from 'react';
import { Link } from 'react-router-dom';

import './SimpleCard.scss';
import ImageWithPlaceholder from 'components/ImageWithPlaceholder/ImageWithPlaceholder';

type Props = {
  button?: Element<any>,
  children?: Node,
  image: string,
  newTab?: boolean,
  overlayCopy?: string,
  path: string,
  title: string
};

/**
 * The base card component used for composing more specialized
 * cards.
 *
 * Props
 * button - icon component to be output in the button section
 * children - any children passed will be output in the content section
 * image - image src for the image to be output in the top section
 * overlayCopy - text to output in the overlay in hover state
 * path - route to navigate to when the user clicks the image or button
 * title - card title to output in content section
 *
 * @author Todd Miller <github.com/Toddses>
 */
const SimpleCard = (props: Props) => {
  const {
    button = null,
    children = null,
    image,
    newTab = false,
    overlayCopy = '',
    path,
    title
  } = props;
  // use state to manage hover overlay because we need to trigger
  // the hover state when the user hovers over the button as well
  const [active, setActive] = useState(false);

  const mouseEnterHandler = () => {
    setActive(true);
  };

  const mouseLeaveHandler = () => {
    setActive(false);
  };

  return (
    <div className="simple-card">
      <div
        className="thumbnail"
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
      >
        {newTab ? (
          <a href={path} target="_blank" rel="noopener noreferrer">
            Click to View
          </a>
        ) : (
          <Link to={path} />
        )}

        <div className={`overlay ${active ? 'is-active' : ''}`}>
          <div className="background" />
          <div className="copy">{overlayCopy}</div>
        </div>

        <ImageWithPlaceholder src={image} alt={title} />
        <div className="radial-overlay" />
      </div>

      <div className="content">
        <div className="info">
          <p className="w-bold">{title}</p>
          {children}
        </div>

        {button ? (
          <div className="buttons">
            {newTab ? (
              <a
                href={path}
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
              >
                {button}
              </a>
            ) : (
              <Link
                to={path}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
              >
                {button}
              </Link>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SimpleCard;
