// @flow

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterHistory } from 'react-router-dom';

import BackIcon from 'icons/ArrowLeftIcon';
import PlantsView from 'components/PlantsView/PlantsView';
import VirtualPlantIcon from 'icons/VirtualPlantIcon';
import { deactivateButtBar } from 'store/butt/actions';
import { setCurrent } from 'store/current/actions';
import { getIsFetching, getPlantStructure } from 'store/plant/selectors';
import {
  createWidget,
  enableWidgets,
  removeWidgets,
  setTheme
} from 'store/sidebar/actions';

type Props = {
  history: RouterHistory
};

const PlantsContainer = (props: Props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const isPlantsFetching = useSelector(getIsFetching);
  const plants = useSelector(getPlantStructure);
  const plantIcon = <VirtualPlantIcon iconTitle="virtual-plant-icon" />;

  // removes potential 'carry-over' with logo navigation
  dispatch(deactivateButtBar());

  useEffect(() => {
    if (isPlantsFetching) dispatch(setCurrent('LoadingActive', true));
    else dispatch(setCurrent('LoadingActive', false));

    return () => {
      dispatch(setCurrent('LoadingActive', true));
    };
  }, [dispatch, isPlantsFetching]);

  useEffect(() => {
    const backIcon = <BackIcon iconTitle="plant-back-icon" />;
    const plantsBack = () => {
      history.push('/');
    };
    dispatch(createWidget('plants-back', 'Back', backIcon, plantsBack));
    return () => {
      dispatch(removeWidgets(['plants-back']));
    };
  }, [dispatch, history]);

  useEffect(() => {
    const widgets = process.env.REACT_APP_SHOW_FEEDBACK
      ? ['empty', 'feedback', 'plants-back']
      : ['empty', 'plants-back'];

    dispatch(enableWidgets(widgets));
    dispatch(setTheme('none'));
  }, [dispatch]);

  return <PlantsView plants={plants} plantIcon={plantIcon} />;
};

export default PlantsContainer;
