//@flow

import React from 'react';
import { useSelector } from 'react-redux';

import './LiteraturePager.scss';
import ChevronLeftIcon from 'icons/ChevronLeftIcon';
import ChevronRightIcon from 'icons/ChevronRightIcon';

import { getProductTitle } from 'store/product/selectors';

type Props = {
  id: string,
  type: string,
  pagerOnClick: (string) => void
};

/**
 * Pager for previous and next products, includes icon,
 * title, and type for each.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const LiteraturePager = (props: Props) => {
  const { id, type, pagerOnClick } = props;

  /* selector grabs title for product, based on product id */
  const pagerTitle = useSelector(getProductTitle(id));

  const handleOnPagerClick = () => {
    if (!pagerOnClick) return;
    pagerOnClick(id);
  };

  return (
    <div className={`pager-item ${type}`} onClick={handleOnPagerClick}>
      <div className="chevron-nav">
        {type === 'next' ? (
          <ChevronRightIcon iconTitle="chevron-right-icon" />
        ) : (
          <ChevronLeftIcon iconTitle="chevron-left-icon" />
        )}
      </div>
      <div className="item-info">
        <div className="pager-type">{type}</div>
        <h3>{pagerTitle}</h3>
      </div>
    </div>
  );
};

export default LiteraturePager;
