// @flow

import { Get } from 'api';
import { createAsset } from 'store/asset/actions';

import type {
  DamAsset,
  DamStructureResponse,
  Dispatch,
  GetState,
  Plant
} from 'types';
// ============================
// ACTION TYPES
// ============================
export const ACTIONS = {
  PLANT: {
    REQUEST: 'PLANTS::REQUEST',
    RECEIVE: 'PLANTS::RECEIVE',
    ERROR: 'PLANTS::ERROR',
    DISABLE: 'PLANTS::DISABLE',
    ENABLE: 'PLANTS::ENABLE',
    MODAL: {
      SHOW: 'PLANTS::MODAL::SHOW',
      HIDE: 'PLANTS::MODAL::HIDE'
    }
  }
};

// ============================
// ACTION CREATORS
// ============================
function _receivePlants(plants: Plant[]) {
  return {
    type: ACTIONS.PLANT.RECEIVE,
    plants
  };
}

function _requestPlants() {
  return {
    type: ACTIONS.PLANT.REQUEST
  };
}

function _errorPlants(error: { message: string }) {
  return {
    type: ACTIONS.PLANT.ERROR,
    error
  };
}

function _disablePlants() {
  if (process.env.REACT_APP_IS_PUBLIC === '1') {
    return {
      type: ACTIONS.PLANT.DISABLE
    };
  }
}

function _enablePlants() {
  return {
    type: ACTIONS.PLANT.ENABLE
  };
}

function _showModal() {
  if (process.env.REACT_APP_IS_PUBLIC === '1') {
    return {
      type: ACTIONS.PLANT.MODAL.SHOW
    };
  }
}

function _hideModal() {
  return {
    type: ACTIONS.PLANT.MODAL.HIDE
  };
}

// ============================
// ACTION METHODS
// ============================
export const fetchPlants = () => (dispatch: Dispatch, getState: GetState) => {
  // if we already fetched plants, no need to do it again
  if (getState().plant.plants.length) return;

  dispatch(_requestPlants());

  Get('virtualplants/structure')
    .then((response: DamStructureResponse) => {
      if (response.status !== 200) {
        dispatch(_errorPlants(response.data));
        return [];
      }

      const plants = [];

      // normalize the response
      response.data.forEach((plant) => {
        const assetIds = [];

        plant.assets.forEach((asset: DamAsset) => {
          dispatch(createAsset(asset));
          assetIds.push(asset.id);
        });

        plants.push({
          id: plant.id,
          card: plant.card.url,
          overview: plant.overview.url,
          title: plant.title,
          units: plant.units,
          assets: assetIds
        });
      });

      return plants;
    })
    .then((plants) => {
      dispatch(_receivePlants(plants));
    });
};

export const checkPlant = () => (dispatch: Dispatch) => {
  if (process.env.REACT_APP_IS_PUBLIC === '1') {
    if (document.cookie.includes('virtualplantGateSuccess=true')) {
      dispatch(_enablePlants());
      dispatch(_hideModal());
    } else {
      dispatch(_disablePlants());
    }
  } else {
    dispatch(_enablePlants());
    dispatch(_hideModal());
  }
};

export const closeModal = () => (dispatch: Dispatch) => {
  dispatch(_hideModal());
  if (document.cookie.includes('virtualplantGateSuccess=true'))
    dispatch(_enablePlants());
};

export const showModal = () => (dispatch: Dispatch) => {
  dispatch(_showModal());
};
