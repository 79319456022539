// @flow

import React from 'react';

type Props = {
  iconTitle: string
};

const CircleDocumentIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      aria-labelledby={props.iconTitle}
    >
      <title id={props.iconTitle}>Document Icon</title>

      <g>
        <path d="M20.5,41C9.2,41,0,31.8,0,20.5S9.2,0,20.5,0S41,9.2,41,20.5S31.8,41,20.5,41z M20.5,1C9.7,1,1,9.7,1,20.5 C1,31.3,9.7,40,20.5,40C31.3,40,40,31.3,40,20.5C40,9.7,31.3,1,20.5,1z" />
      </g>
      <path d="M26.8,13.7l-4.3-4.3c-0.7-0.7-1.6-1-2.5-1l0,0h-6c-1.9,0-3.5,1.6-3.5,3.5V28c0,1.9,1.6,3.5,3.5,3.5h9.2V30H14 c-1.1,0-2-0.9-2-2V11.8c0-1.1,0.9-2,2-2h5.9l0,0c0.1,0,0.2,0,0.3,0v6h6c0,0.1,0,0.2,0,0.3v1.3h1.5v-1.3 C27.8,15.3,27.4,14.4,26.8,13.7z M21.7,10.8l3.6,3.6h-3.6V10.8z" />
      <path d="M34,30.6l-3-3c0.6-0.9,1-1.9,1-3.1c0-2.8-2.3-5.1-5.1-5.1s-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1c1.1,0,2.2-0.4,3.1-1l3,3 L34,30.6z M26.9,28.1c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6C30.6,26.5,29,28.1,26.9,28.1z" />
    </svg>
  );
};

export default CircleDocumentIcon;
