// @flow

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import './Menu.scss';

import type { Node } from 'react';

type Props = {
  active: boolean,
  children?: Node,
  tier: 1 | 2
};

/**
 * Generic Menu container to wrap up MenuItems of any type.
 * - active determines if the Menu should be open or not
 * - outputs passed children
 * - applies position and some unique styles based on the given tier value
 *
 * Provides a Scrollbar when needed
 *
 * @author Todd Miller <github.com/Toddses>
 */
const Menu = (props: Props) => {
  const { active, children, tier } = props;

  return (
    <div className={`menu-container tier-${tier} ${active ? 'is-active' : ''}`}>
      <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
        <div className="menu-items">{children}</div>
      </Scrollbars>
    </div>
  );
};

export default Menu;
