// @flow

import React from 'react';

import './Hotspot.scss';
import ChevronRightIcon from 'icons/ChevronRightIcon';
import HotspotIcon from 'icons/HotspotIcon';

type Props = {
  active: boolean,
  disabled: boolean,
  copy: string,
  id: string,
  image: string,
  modalHorizontal: 'left' | 'right',
  modalVertical: 'top' | 'bottom',
  x: string | number,
  y: string | number,
  onClick?: (string) => void,
  onMouseEnter?: (string) => void,
  onMouseLeave?: (string) => void
};

/**
 * Hotspot marker icon with unit snapshot modal when active. Also has a
 * disabled state where the marker icon appears dimmed and will not
 * fire events
 *
 * Modal positioners determine where the unit snapshot modal will
 * appear in relation to the marker icon
 *
 * x/y positioners will position the marker icon relative to its' parent
 * using these values as percentages
 *
 * Optionally pass three event handlers - click, mouseenter, and mouseleave
 * These will be called with this hotspot's id as the only argument
 *
 * @author Todd Miller <https://github.com/Toddses>
 */
const Hotspot = (props: Props) => {
  const {
    active,
    disabled,
    copy,
    id,
    image,
    modalHorizontal,
    modalVertical,
    x,
    y,
    onClick,
    onMouseEnter,
    onMouseLeave
  } = props;

  const handleOnClick = () => {
    if (!onClick) return;
    onClick(id);
  };

  const handleOnMouseEnter = () => {
    if (!onMouseEnter) return;
    onMouseEnter(id);
  };

  const handleOnMouseLeave = () => {
    if (!onMouseLeave) return;
    onMouseLeave(id);
  };

  return (
    <div
      className={`hotspot ${active ? 'is-active' : ''} ${
        disabled ? 'is-disabled' : ''
      }`}
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      style={{ left: `${x}%`, top: `${y}%` }}
    >
      <div className="icon">
        <HotspotIcon iconTitle={`hotspot-${id}`} />
      </div>

      <div className={`unit-snapshot ${modalVertical} ${modalHorizontal}`}>
        <img className="thumbnail" src={image} alt="" />
        <div className="copy">
          <h4>{copy}</h4>
          <div className="cta">
            <p>Learn More</p>
            <ChevronRightIcon iconTitle="learn-more-chevron" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hotspot;
