// @flow

import React from 'react';

import './Title.scss';

type Props = {
  copy: string,
  theme: 'light' | 'dark' | 'none'
};

/**
 * Outputs the Title with the given copy, styled with
 * the given theme.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const Title = (props: Props) => {
  const { copy, theme } = props;

  return (
    <div className={`title-container ${theme}`}>
      <h1 className="copy">{copy}</h1>
    </div>
  );
};

export default Title;
