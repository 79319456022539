// @flow

import React from 'react';

import './Transitions.scss';
import Panel from 'components/Panel/Panel';
import Transition from '../Transition/Transition';

type Props = {
  onEnded: (string) => void,
  transits: any
};

/**
 * Outputs the container elements wrapping up the list
 * of transits that were passed in, outputting a Transition
 * component for each.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const Transitions = (props: Props) => {
  const { onEnded, transits } = props;

  return (
    <div id="transitions-container">
      <Panel type="butt">
        {Object.keys(transits).map((id) => {
          return (
            <Transition
              key={id}
              active={transits[id].active}
              id={id}
              onEnded={onEnded}
              src={transits[id].src}
            />
          );
        })}
      </Panel>
    </div>
  );
};

export default Transitions;
