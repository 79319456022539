// @flow

import React from 'react';

import './DrawerItem.scss';
import ChevronRightIcon from 'icons/ChevronRightIcon';

type Props = {
  active: boolean,
  copy: string,
  handler: () => void
};

/**
 * Outputs and styles a list item to be used in the Drawer.
 * - active applies unique styles for an active state
 * - copy is the text output for the item
 * - handler is the function to execute when the item is clicked
 *
 * @author Todd Miller <github.com/Toddses>
 */
const DrawerItem = (props: Props) => {
  const { active, copy, handler } = props;

  return (
    <div
      className={`drawer-item ${active ? 'is-active' : ''}`}
      onClick={handler}
    >
      {copy}
      <ChevronRightIcon iconTitle="drawer-item-chevron-icon" />
    </div>
  );
};

export default DrawerItem;
