// @flow
import { Get } from 'api';
import { createAsset } from 'store/asset/actions';
import type {
  DamAsset,
  DamProductResponse,
  Dispatch,
  GetState,
  Product
} from 'types';

import defaultCard from 'images/default-card.jpg';
import defaultHero from 'images/default-hero.jpg';

// ============================
// ACTION TYPES
// ============================
export const ACTIONS = {
  PRODUCT: {
    REQUEST: 'PRODUCT::REQUEST',
    RECEIVE: 'PRODUCT::RECEIVE',
    ERROR: 'PRODUCT::ERROR'
  }
};

// ============================
// ACTION CREATORS
// ============================
function _receiveProduct(product: Product) {
  return {
    type: ACTIONS.PRODUCT.RECEIVE,
    product
  };
}

function _requestProduct(id: string) {
  return {
    type: ACTIONS.PRODUCT.REQUEST,
    id
  };
}

function _errorProduct(id: string, error: { message: string }) {
  return {
    type: ACTIONS.PRODUCT.ERROR,
    id,
    error
  };
}

// ============================
// ACTION METHODS
// ============================
export const fetchProduct = (id: string = '') => (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (!id || getState().product.hasOwnProperty(id)) return false;

  dispatch(_requestProduct(id));

  Get(`products/${id}`).then((response: DamProductResponse) => {
    if (response.status !== 200) {
      dispatch(_errorProduct(id, response.data));
      return false;
    }

    const damProduct = response.data;
    const assetKeys = Object.keys(damProduct.assets);

    let normalizedAssets = {
      marketing: [],
      technical: [],
      videos: [],
      images: [],
      instructions: [],
      training: []
    };

    for (let i = 0; i < assetKeys.length; i++) {
      const assets = damProduct.assets[assetKeys[i]];

      assets.forEach((asset: DamAsset) => {
        dispatch(createAsset(asset));

        switch (assetKeys[i]) {
          case 'videos':
            normalizedAssets.videos.push(asset.id);
            break;
          case 'images':
            normalizedAssets.images.push(asset.id);
            break;
          case 'presentations':
            normalizedAssets.training.push(asset.id);
            break;
          case 'literature':
            switch (asset.literature_type) {
              case 'Article':
              case 'Brochures and Flyers':
              case 'Case Study':
              case 'Catalog':
              case 'Face to Face Magazine':
              case 'Miscellaneous':
              case 'Poster':
                normalizedAssets.marketing.push(asset.id);
                break;
              case 'Certification':
              case 'Data Sheet':
              case 'Specification Sheet':
              case 'Technical Brochure':
              case 'White Paper':
                normalizedAssets.technical.push(asset.id);
                break;
              case 'User Instruction Manual (IOM)':
                normalizedAssets.instructions.push(asset.id);
                break;
              default:
                if (process.env.REACT_APP_DEBUG_LOGGING)
                  console.debug(
                    `Unknown literature type "${assetKeys[i]}", asset not created.`
                  );
                return;
            }
            break;
          default:
            if (process.env.REACT_APP_DEBUG_LOGGING)
              console.debug(
                `Unknown asset category "${assetKeys[i]}", asset not created.`
              );
            return;
        }
      });
    }

    dispatch(
      _receiveProduct({
        id: damProduct.id,
        title: damProduct.title,
        productType: damProduct.product_type,
        pumpType: damProduct.pump_type,
        apiDesignation: damProduct.api_designation,
        brand: damProduct.brand,
        certification: damProduct.certification,
        description: damProduct.description,
        featuresBenefits: damProduct.features_and_benefits,
        card: damProduct.vpcard ? damProduct.vpcard.url : defaultCard,
        image: damProduct.main_image ? damProduct.main_image.url : defaultHero,
        shortDescription: damProduct.short_description,
        assets: normalizedAssets
      })
    );
  });
};
