// @flow

import React from 'react';

import './ButtBarItem.scss';

type Props = {
  active: boolean,
  enabled: boolean,
  hover: boolean,
  id: string,
  title: string,
  onClick?: (string) => void,
  onMouseEnter?: (string) => void,
  onMouseLeave?: (string) => void
};

/**
 * Singular item in the ButtBar.
 *
 * OnClick will route the user further into the app.
 *
 * Is a generic component, appears in Plant and Unit views,
 * and Transition feature.
 *
 * @author Abigail Kesler <https://github.com/abbingail>
 */

const ButtBarItem = (props: Props) => {
  const {
    active,
    enabled,
    hover,
    id,
    title,
    onClick,
    onMouseEnter,
    onMouseLeave
  } = props;

  const handleOnClick = () => {
    if (!onClick) return;
    onClick(id);
  };

  const handleOnMouseEnter = () => {
    if (!onMouseEnter) return;
    onMouseEnter(id);
  };

  const handleOnMouseLeave = () => {
    if (!onMouseLeave) return;
    onMouseLeave(id);
  };

  return (
    <div
      className={`bb-item ${active ? 'is-active' : ''} ${
        hover ? 'is-active' : ''
      } ${enabled ? '' : 'is-disabled'}`}
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <h5 className="w-bold">{title}</h5>
    </div>
  );
};

export default ButtBarItem;
