// @flow

import React, { useEffect, useRef } from 'react';
import VideoCover from 'react-video-cover';

import './Transition.scss';

type Props = {
  active: boolean,
  id: string,
  onEnded: (string) => void,
  src: string
};

/**
 * Outputs the actual video element with the active state
 * showing and then playing the video when true. Also calls
 * the passed onEnded function when the video finishes playing.
 *
 * @author Todd Miller <github.com/Toddses>
 */
const Transition = (props: Props) => {
  const { active, id, onEnded, src } = props;
  const video = useRef<?HTMLVideoElement>(null);

  useEffect(() => {
    const vidRef = video.current;

    if (!vidRef) return;

    if (active) {
      setTimeout(() => {
        vidRef.play();
      }, 100);
    } else {
      setTimeout(() => {
        vidRef.currentTime = 0;
      }, 100);
    }
  }, [active]);

  const handleOnEnded = () => {
    onEnded(id);
  };

  const videoOptions = {
    src: src,
    ref: video,
    onEnded: handleOnEnded
  };

  return (
    <div className={`transit-wrapper ${active ? 'is-active' : ''}`}>
      <VideoCover videoOptions={videoOptions} muted preload="auto" />
    </div>
  );
};

export default Transition;
