// @flow

import { createSelector } from 'reselect';

export const getActiveWidgets = createSelector(
  (state) => state.sidebar,
  (sidebar) => sidebar.activeWidgets
);

/**
 * Return the sidebar theme
 * @returns string
 */
export const getSidebarTheme = createSelector(
  (state) => state.sidebar,
  (sidebar) => sidebar.theme
);

/**
 * Get Sidebar Widget
 * @param id: SidebarWidget ID
 * @returns SidebarWidget
 */
export const getSidebarWidget = (id: string) => {
  return createSelector(
    (state) => state.sidebar,
    (sidebar) => sidebar.widget.filter((widget) => widget.id === id)
  );
};

/**
 * Return array of sidebar widgets
 * @returns SidebarWidget[]
 */
export const getSidebarWidgets = createSelector(
  (state) => state.sidebar,
  (sidebar) => sidebar.widgets
);
