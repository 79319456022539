// @flow

import * as React from 'react';

import './Panel.scss';

type Props = {
  children: React.Node,
  type?: 'butt'
};

/**
 * Panel is a full width and height container
 *
 * Optionally will accept 1 prop - type - that modifies
 * the Panel in some way.
 *
 * @author Matthew Wilson <https://github.com/matthewwewilson>
 */
const Panel = (props: Props) => {
  const { type = '' } = props;

  return <div className={`panel ${type}`}>{props.children}</div>;
};

export default Panel;
